import { Icon } from '@ui-library/core'
import styled, { css } from 'styled-components'

type ThBlueProps = {
  $isBlue?: boolean
}

type IconStyledProps = {
  $isBlue?: boolean
}

type ThCellFixProps = {
  $isBlue?: boolean
}

export const ThBlue = styled.th<ThBlueProps>`
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) =>
    props.$isBlue &&
    css`
      color: ${(p) => p.theme.global.colorPalette.saphir};
      font-weight: 600;
    `}
`

export const IconStyled = styled(Icon)<IconStyledProps>`
  position: relative;
  top: 1px;
  color: ${(p) => p.theme.global.colorPalette.greyLight};

  ${(props: any) =>
    props.$isBlue &&
    css`
      color: ${(p) => p.theme.global.colorPalette.saphir};
    `}
`

export const ThCellFix = styled.th<ThCellFixProps>`
  width: 28%;
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) =>
    props.$isBlue &&
    css`
      color: ${(p) => p.theme.global.colorPalette.saphir};
      font-weight: 600;
    `}
`
