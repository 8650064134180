import { Icon } from '@ui-library/core'
import { LoaderRegular } from '@ui-library/icons'
import React from 'react'

export const LoadingList = ({
  centered,
}: {
  centered?: boolean
}): React.ReactElement =>
  centered ? (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader />
    </div>
  ) : (
    <Loader />
  )

const Loader = (): React.ReactElement => (
  <Icon
    data-testid="loadingIcon"
    className="loader"
    icon={LoaderRegular}
    size="2.625em"
    testID="loadingIcon"
  />
)
