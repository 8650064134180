import { Button, Icon } from '@ui-library/core'
import { CheckBold, CloseBold, LoaderRegular } from '@ui-library/icons'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import {
  boostNotificationSelector,
  boostNotificationVisibleSelector,
} from '../../../../redux/reducers/Page/Boosts/pageBoost.reducer'

export const FixedBoostPrograms = ({
  maxProgramsCount,
  notificationVisible,
  setOverlayBoostPrograms,
  buttonDisabled,
  loading,
}: {
  maxProgramsCount: number
  notificationVisible: boolean
  setOverlayBoostPrograms: (value: boolean) => void
  buttonDisabled: boolean
  loading: string
}): React.ReactElement => {
  const notification = {
    status: 'error',
    title: 'Oups !',
    description:
      maxProgramsCount <= 1
        ? `${maxProgramsCount} sur ${maxProgramsCount} programme a déjà été sélectionné`
        : `${maxProgramsCount} sur ${maxProgramsCount} programmes ont déjà été sélectionnés`,
  }

  const boostNotification = useSelector(boostNotificationSelector)
  const boostNotificationVisible = useSelector(boostNotificationVisibleSelector)

  const refMaxBoostableToaster = useRef(null)
  const refBoostNotificationToaster = useRef(null)

  const boostButtonTitle =
    maxProgramsCount <= 1 ? 'Booster le programme' : 'Booster les programmes'
  return (
    <FixedBoostProgramsWrapper ref={refMaxBoostableToaster}>
      <Button
        kind="primary"
        size="medium"
        tag="button"
        variant="normal"
        onClick={() => setOverlayBoostPrograms(true)}
        disabled={buttonDisabled}
        data-testid="boostButton"
      >
        {loading === 'loading' ? (
          <Icon
            testID="iconLoading"
            icon={LoaderRegular}
            style={{ display: 'flex' }}
          />
        ) : (
          boostButtonTitle
        )}
      </Button>
      <CSSTransition
        nodeRef={refMaxBoostableToaster}
        in={notificationVisible}
        timeout={2500}
        classNames="visible"
        appear
        unmountOnExit
      >
        <Toaster
          data-testid="notificationSavedCount"
          className="visible"
          $error
        >
          <IconDiv $error>
            <Icon icon={CloseBold} />
          </IconDiv>
          <ToasterTextContainer>
            <strong>{notification.title}</strong>
            <ToasterText>{notification.description}</ToasterText>
          </ToasterTextContainer>
        </Toaster>
      </CSSTransition>

      <CSSTransition
        nodeRef={refBoostNotificationToaster}
        in={boostNotificationVisible}
        timeout={400}
        classNames="visible"
        appear
        unmountOnExit
      >
        <BoostRecordedNotification
          data-testid="notificationSavedCount"
          className="visible"
          ref={refBoostNotificationToaster}
          $error={boostNotification?.statut === 'error'}
        >
          <IconDiv $error={boostNotification?.statut === 'error'}>
            <Icon
              icon={
                boostNotification?.statut === 'error' ? CloseBold : CheckBold
              }
            />
          </IconDiv>
          <ToasterTextContainer>
            <strong>{boostNotification?.title}</strong>
            <ToasterText>{boostNotification?.description}</ToasterText>
          </ToasterTextContainer>
        </BoostRecordedNotification>
      </CSSTransition>
    </FixedBoostProgramsWrapper>
  )
}

type ProgramsCountToasterProps = {
  $error: boolean
}

type IconDivProps = {
  $error: boolean
}

const sharedToasterStyles = css`
  display: flex;
  opacity: 1;
  align-items: center;
  background: ${(props) => props.theme.global.colorPalette.validationPastel};
  padding: 1.12em 2em 1.12em 0.75em;
  border-radius: 0.4em;

  &.visible-enter {
    opacity: 0;
  }
  &.visible-enter-done {
    opacity: 1;
  }
  &.visible-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  &.visible-exit {
    opacity: 1;
  }
  &.visible-exit-done {
    opacity: 0;
  }
  &.visible-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
`

const FixedBoostProgramsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 256px;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.footer};
  display: flex;
  align-items: center;
  padding: 1.5em 2.5em;
  width: 100%;
  background: ${(props) => props.theme.global.colorPalette.white};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const Toaster = styled.div<ProgramsCountToasterProps>`
  position: absolute;
  top: -5.5em;
  right: 295px;
  ${sharedToasterStyles}

  ${(props) =>
    props.$error &&
    css`
      background: ${props.theme.global.colorPalette.lighterCandy};
    `}
`

export const BoostRecordedNotification = styled.div<ProgramsCountToasterProps>`
  position: absolute;
  top: -5.5em;
  ${sharedToasterStyles}

  ${(props) =>
    props.$error &&
    css`
      background: ${props.theme.global.colorPalette.lighterCandy};
    `}
`

export const IconDiv = styled.div<IconDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.75em;
  border: ${(props) =>
    `1px solid ${props.theme.global.colorPalette.validationPastel}`};

  > div {
    width: 65%;
    color: ${(props) =>
      `1px solid ${props.theme.global.colorPalette.validationPastel}`};
  }

  ${(props) =>
    props.$error &&
    css`
      border-color: ${props.theme.global.colorPalette.error};

      > div {
        color: ${props.theme.global.colorPalette.error};
      }
    `}
`

export const ToasterTextContainer = styled.div`
  margin-left: 0.75em;
  color: ${(props) => props.theme.global.colorPalette.black};
`

export const ToasterText = styled.div`
  font-size: 0.85em;
`
