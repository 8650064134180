import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import {
  getAdsByClient,
  getAllBoostNewBuild,
  getClientsNewBuild,
  getMyBoostNewBuild,
  recordBoost,
} from '../../../../services/boostSocialImmoApi'
import {
  NewBuildAgency,
  NewBuildRequest,
  Programme,
} from '../../../../utils/myAdfactoryApi/swaggerApi'
import {
  networkErrorContent,
  successProgramBoost,
} from '../../../constants/notification'

export interface IPageBoostState {
  loading: string
  error: any
  agencies: NewBuildAgency[]
  myBoostData: any
  allBoostData: any
  programs: Programme[]
  recordedBoost: NewBuildRequest
  boostNotificationVisible: boolean
  boostNotification: any
  rowSelected: {
    x: number
    y: number
    clientId: string
    clientName: string
    nbMaxBoost: number
  }
}

const initialState: IPageBoostState = {
  loading: 'idle',
  error: null,
  agencies: [],
  myBoostData: [],
  allBoostData: [],
  programs: [],
  recordedBoost: {},
  boostNotificationVisible: false,
  boostNotification: null,
  rowSelected: {
    x: 0,
    y: 0,
    clientId: '',
    clientName: '',
    nbMaxBoost: 0,
  },
}

// Slice
const pageBoostSlice = createSlice({
  name: 'pageBoost',
  initialState,
  reducers: {
    hideBoostNotification(state) {
      state.boostNotificationVisible = false
    },
    saveSelectedRow(state, action) {
      state.rowSelected.x = action.payload.x
      state.rowSelected.y = action.payload.y
      state.rowSelected.clientName = action.payload.clientName
      state.rowSelected.clientId = action.payload.clientId
      state.rowSelected.nbMaxBoost = action.payload.nbMaxBoost
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.loading = 'idle'
        state.error = null
        state.myBoostData = []
        state.allBoostData = []
        state.programs = []
        state.recordedBoost = {}
        state.boostNotificationVisible = false
        state.boostNotification = null
      })
      .addCase(getClientsNewBuild.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(getClientsNewBuild.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.agencies = action.payload
        state.error = null
      })
      .addCase(getClientsNewBuild.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })

      .addCase(getMyBoostNewBuild.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(getMyBoostNewBuild.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.myBoostData = action.payload
        state.error = null
      })
      .addCase(getMyBoostNewBuild.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })

      .addCase(getAllBoostNewBuild.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(getAllBoostNewBuild.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.allBoostData = action.payload
        state.error = null
      })
      .addCase(getAllBoostNewBuild.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })

      .addCase(getAdsByClient.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(getAdsByClient.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.programs = action.payload
        state.error = null
      })
      .addCase(getAdsByClient.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })
      
      .addCase(recordBoost.pending, (state) => {
        state.loading = 'loading'
      })
      .addCase(recordBoost.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.recordedBoost = action.payload
        state.boostNotificationVisible = true
        state.error = null
        state.boostNotification = successProgramBoost(
          state.recordedBoost.programmes?.filter((p) => p.isChecked).length ?? 0
        )
      })
      .addCase(recordBoost.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
        state.boostNotificationVisible = true
        state.boostNotification = networkErrorContent
      })
  },
})

// Selectors
type PageBoostSLiceName = (typeof pageBoostSlice)['name']
export type PageBoostStateType = Record<PageBoostSLiceName, IPageBoostState>

export const loadingApiBoostSelector = (state: PageBoostStateType) =>
  state.pageBoost.loading

export const boostErrorsSelector = (state: PageBoostStateType) =>
  state.pageBoost.error

export const myBoostDataSelector = (state: PageBoostStateType) =>
  state.pageBoost.myBoostData.boostBo
export const allBoostDataSelector = (state: PageBoostStateType) =>
  state.pageBoost.allBoostData.boostBo

export const boostNotificationSelector = (state: PageBoostStateType) =>
  state.pageBoost.boostNotification
export const boostNotificationVisibleSelector = (state: PageBoostStateType) =>
  state.pageBoost.boostNotificationVisible

export const rowSelectedBoostSelector = (state: PageBoostStateType) =>
  state.pageBoost.rowSelected

// Actions
export const { hideBoostNotification, saveSelectedRow } = pageBoostSlice.actions

// The reducer
export default pageBoostSlice.reducer
