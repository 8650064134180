import { Button, Icon } from '@ui-library/core'
import { CloseRegular } from '@ui-library/icons'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { productNamesType } from '../../../../types/productNames.types'
import { NewBuildAgency } from '../../../../utils/myAdfactoryApi/swaggerApi'
import AutocompleteUnique from '../../../Autocomplete/AutocompleteUnique/AutocompleteUnique'
import Overlay from '../../Overlay'

type OverlayNewBuildsRcClientProps = {
  url: string
  closeOverlay: () => void
}

export const OverlayNewBuildsRcClient = ({
  url,
  closeOverlay,
}: OverlayNewBuildsRcClientProps) => {
  const history = useHistory()

  const [errorNewBuildsRcClient, setErrorNewBuildsRcClient] =
    useState<boolean>(false)
  const [valueNewBuildsRcClient, setValueNewBuildsRcClient] = useState<
    NewBuildAgency[]
  >([])

  const handleRcClient = useCallback((value: NewBuildAgency[]) => {
    setValueNewBuildsRcClient(value)
    if (value.length !== 0) {
      setErrorNewBuildsRcClient(false)
    }
  }, [])

  const setRcClient = () => {
    history.push(url)
    sessionStorage.setItem(
      'boostSocialImmoData',
      JSON.stringify({ ...valueNewBuildsRcClient?.[0] })
    )
  }

  const disableOverlay = () => {
    closeOverlay()
  }

  return (
    <Overlay closeOverlay={() => disableOverlay()}>
      <div className="overlayRcCLient" data-testid="overlayNewBuildsRcClient">
        <div
          data-testid="closeOverlay"
          className="closeOverlay"
          onClick={() => {
            disableOverlay()
          }}
        >
          <Icon icon={CloseRegular} />
        </div>

        <div className="wrapperOverlayRcClient">
          <h2 data-testid="overlayNewBuildsRcClientTitle">
            {productNamesType.BoostSocialImmo}
          </h2>
          <p>
            Saisissez le numéro RC ou la raison sociale de votre client pour
            afficher les programmes éligibles au Boost.
          </p>
          <AutocompleteUnique
            source="boostSocialImmo"
            label="RC client ou raison sociale"
            placeholder="Ex: 123456 ou Nexity"
            autocompleteCallback={(selected) => handleRcClient(selected)}
            error={errorNewBuildsRcClient}
            noResultFound="Ce RC client est introuvable. Merci de vous rapprocher de votre marché pour vérifier l'éligibilité de votre client."
          />
          <div
            className="wrapperbuttonValidate"
            data-testid="overlayNewBuildsRcClientButton"
          >
            <Button
              tag="button"
              size="medium"
              kind="primary"
              variant="normal"
              onClick={setRcClient}
              disabled={valueNewBuildsRcClient.length === 0}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  )
}
