import { Button, Icon } from '@ui-library/core'
import Tabs from '@ui-library/core/Tabs'
import { AddBold } from '@ui-library/icons'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setCountsSearch } from '../../../redux/reducers/Page/Counts/pageCounts.reducer'
import { setUsersSearch } from '../../../redux/reducers/Page/Users/pageUsers.reducer'
import {
  currentMarketSelector,
  setTabListBoost,
  setTabListCount,
  tabListBoostSelector,
  tabListCountSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { BOOST_PRODUITS, COMPTAGE_PRODUITS } from '../../../routes.constants'
import {
  getAllBoostNewBuild,
  getMyBoostNewBuild,
} from '../../../services/boostSocialImmoApi'
import { getAllCounts, getMyCounts } from '../../../services/countApi'
import { getAllUsers } from '../../../services/userApi'
import { ITEMS_PER_PAGE } from '../../../utils/variables'
import SearchInput from '../../Input/SearchInput/SearchInput'

type HeaderListProps = {
  buttonObj: any
  title: string
  choices: {
    label: string
    id: string
    ariaControls: string
  }[]
  userProfile?: any
  listType: 'users' | 'counts' | 'boost' | undefined
  setCurrentPage?: any
}

export const removePrefixMaf = (searchCount: string) => {
  if (searchCount.toLowerCase().includes('maf-')) {
    return searchCount.toLowerCase().replace('maf-', '')
  }
  return searchCount
}

const HeaderList = ({
  buttonObj,
  title,
  choices,
  userProfile,
  listType,
  setCurrentPage,
}: HeaderListProps): React.ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentMarket = useSelector(currentMarketSelector)
  const tabListCount = useSelector(tabListCountSelector)
  const tabListBoost = useSelector(tabListBoostSelector)
  const handleClick = () =>
    (listType === 'counts' && history.push(COMPTAGE_PRODUITS)) ||
    (listType === 'boost' && history.push(BOOST_PRODUITS))
  const page = 1
  const itemsPerPage = ITEMS_PER_PAGE
  const clientId = undefined
  const productName = undefined
  const market = currentMarket
  const clientName = undefined
  const order = 'CreatedDate'

  // Refs
  const isFirstLoad = useRef<boolean>(true)

  const orderDirection = 'desc'
  const handleChangeTab = (tab: any) => {
    setCurrentPage(1)
    if (listType !== 'counts' && listType !== 'boost') return

    if (listType === 'counts') {
      if (tab?.id === 'tab-1') {
        dispatch(setTabListCount('myCounts'))
        dispatch(
          getMyCounts({
            page,
            itemsPerPage,
            clientId: userProfile.id,
            order: undefined,
            orderDirection: undefined,
            market,
            searchCount: undefined,
          })
        )
      } else {
        dispatch(setTabListCount('allCounts'))
        dispatch(
          getAllCounts({
            page,
            itemsPerPage,
            clientId,
            productName,
            market,
            clientName,
            order,
            orderDirection,
            searchCount: undefined,
          })
        )
      }
    } else if (listType === 'boost') {
      if (tab?.id === 'tab-1') {
        dispatch(setTabListBoost('myBoost'))
        dispatch(getMyBoostNewBuild(null))
      } else {
        dispatch(setTabListBoost('allBoost'))
        dispatch(getAllBoostNewBuild(null))
      }
    }
  }

  const handleFirstLoad = () => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false
    }
  }

  const handleCountSearch = (searchCount: string) => {
    const formattedSearchValue = removePrefixMaf(searchCount)

    handleFirstLoad()
    setCurrentPage(1) // Set current page to 1 for pagination
    dispatch(setCountsSearch({ search: searchCount })) // Set search value in store  for pagination

    if (tabListCount === 'myCounts') {
      dispatch(
        getMyCounts({
          page: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          clientId: userProfile?.id,
          order: undefined,
          orderDirection: undefined,
          market: currentMarket,
          searchCount: formattedSearchValue,
        })
      )
    } else {
      dispatch(
        getAllCounts({
          page,
          itemsPerPage,
          clientId,
          productName,
          market: currentMarket,
          clientName,
          order,
          orderDirection,
          searchCount,
        })
      )
    }
  }

  const handleUserSearch = async (searchUser: string) => {
    handleFirstLoad()
    setCurrentPage(1)
    dispatch(setUsersSearch({ search: searchUser })) // Set search value in store  for pagination
    dispatch(
      getAllUsers({
        page: 1,
        order: 'lastName',
        orderDirection: 'asc',
        itemsPerPage: ITEMS_PER_PAGE,
        searchUser: searchUser,
      })
    )
  }

  return (
    <div className="listsHeaderWrapper" data-testid="headerList">
      <div className="listsHeader">
        <div className="listsHeaderTitle">
          <h2>{title}</h2>
        </div>
        {buttonObj.haveButton && (
          <Button
            testID="headerListButton"
            tag="button"
            size="medium"
            kind="primary"
            variant="normal"
            className="newListButton"
            onClick={handleClick}
          >
            <Icon icon={AddBold} className="addIcon" />
            <span className="newListText">{buttonObj.btnLabel}</span>
          </Button>
        )}
      </div>
      <div className="listsTabs">
        <Tabs
          kind="secondary"
          type="normal"
          choices={choices}
          selected={
            (listType === 'counts' && tabListCount === 'myCounts') ||
            (listType === 'boost' && tabListBoost === 'myBoost')
              ? 'tab-1'
              : 'tab-2'
          }
          onChange={(item) => {
            handleChangeTab(item)
          }}
          showBottomLine
          size={200}
        />
      </div>
      {listType === 'counts' && (
        <SearchInput
          placeholder="Rechercher un comptage (par nom ou par ID MAF)"
          handleChange={handleCountSearch}
        />
      )}
      {listType === 'users' && (
        <SearchInput
          placeholder="Rechercher un utilisateur (par nom, prénom ou par email)"
          handleChange={handleUserSearch}
        />
      )}
    </div>
  )
}

export default HeaderList
