import { SortLined, SortUpLined } from '@ui-library/icons'
import React from 'react'
import { TableHeadProps } from '../../../../types/Tables.type'
import { IconStyled, ThBlue } from '../../../Tables/common/TableHeadCssProps'

export const TableHeadBoost = ({
  col,
  currentPage,
  setCol,
  setSortBy,
  sortBy,
  getData,
}: TableHeadProps): React.ReactElement => {
  const handleSort = (order: string) => {
    const newSortBy = { ...sortBy }
    if (col !== order) {
      // click on another column, sort by the chosen column (desc by default).
      newSortBy[order] = 'desc'
      setCol(order)
    } else {
      // click on the same column, reverse the order direction.
      newSortBy[order] = sortBy[order] === 'desc' ? 'asc' : 'desc'
    }
    setSortBy(newSortBy)
    // Update counts with the new order.
    getData(currentPage, order, newSortBy[order])
  }

  // Handle sort icon
  const arrowSortIcon = (column: string) => {
    // click on another column
    if (column !== col) return SortLined
    // click on the same column
    if (sortBy[column] === 'asc') return SortUpLined
    return SortLined
  }

  return (
    <thead data-testid="table.Header">
      <tr>
        <ThBlue
          data-testid="clientId"
          $isBlue={col === 'ClientId'}
          onClick={() => handleSort('ClientId')}
        >
          RC Client{' '}
          <IconStyled
            data-testid="clientIdIcon"
            $isBlue={col === 'ClientId'}
            icon={arrowSortIcon('ClientId')}
            size="16px"
          />
        </ThBlue>
        <ThBlue
          data-testid="productName"
          $isBlue={col === 'ProductName'}
          onClick={() => handleSort('ProductName')}
        >
          Produit{' '}
          <IconStyled
            data-testid="productNameIcon"
            $isBlue={col === 'ProductName'}
            icon={arrowSortIcon('ProductName')}
            size="16px"
          />
        </ThBlue>
        <ThBlue
          data-testid="programsCount"
          $isBlue={col === 'ProgramsCount'}
          onClick={() => handleSort('ProgramsCount')}
        >
          Programme(s){' '}
          <IconStyled
            data-testid="programsCountIcon"
            $isBlue={col === 'ProgramsCount'}
            icon={arrowSortIcon('ProgramsCount')}
            size="16px"
          />
        </ThBlue>
        <ThBlue
          data-testid="createdDate"
          $isBlue={col === 'CreatedDate'}
          onClick={() => handleSort('CreatedDate')}
        >
          Créé le{' '}
          <IconStyled
            data-testid="createdDateIcon"
            $isBlue={col === 'CreatedDate'}
            icon={arrowSortIcon('CreatedDate')}
            size="16px"
          />
        </ThBlue>
        <ThBlue
          data-testid="bsiId"
          $isBlue={col === 'BsiId'}
          onClick={() => handleSort('BsiId')}
        >
          ID BSI{' '}
          <IconStyled
            data-testid="idIcon"
            $isBlue={col === 'BsiId'}
            icon={arrowSortIcon('BsiId')}
            size="16px"
          />
        </ThBlue>
      </tr>
    </thead>
  )
}
