import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BodyList from '../../../components/Lists/BodyList/BodyList'
import FooterList from '../../../components/Lists/FooterList/FooterList'
import HeaderList from '../../../components/Lists/HeaderList/HeaderList'
import { userSearchSelector } from '../../../redux/reducers/Page/Users/pageUsers.reducer'
import { getAllUsers } from '../../../services/userApi'
import { ITEMS_PER_PAGE, choicesUsers } from '../../../utils/variables'

const UsersList = (): React.ReactElement => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<{ [key: string]: string }>({
    lastName: 'asc',
    firstName: 'desc',
    email: 'desc',
    typeProfil: 'desc',
    market: 'desc',
    id: 'desc',
  })
  const [col, setCol] = useState<string>('lastName')
  const userSearch: string = useSelector(userSearchSelector)

  const handleClick = (page: number) => {
    setCurrentPage(page)
    dispatch(
      getAllUsers({
        page,
        itemsPerPage: ITEMS_PER_PAGE,
        order: col,
        orderDirection: sortBy[col],
        searchUser: userSearch,
      })
    )
  }
  const buttonObj = {
    haveButton: false,
    btnLabel: '',
  }

  return (
    <section className="listsPage">
      <div className="listsContentWrapper">
        <HeaderList
          setCurrentPage={setCurrentPage}
          buttonObj={buttonObj}
          title="Liste des utilisateurs"
          choices={choicesUsers}
          listType="users"
        />
        <BodyList
          currentPage={currentPage}
          setSortBy={setSortBy}
          setCol={setCol}
          sortBy={sortBy}
          col={col}
          mode="users"
        />
        <FooterList
          currentPage={currentPage}
          onClick={handleClick}
          itemsPerPage={ITEMS_PER_PAGE}
          mode="users"
        />
      </div>
    </section>
  )
}

export default UsersList
