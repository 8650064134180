import { combineReducers } from 'redux'

import display from './Display/display.reducer'
import emailing from './Emailing/emailing.reducer'
import expert360 from './Expert360/expert360.reducer'
import masterDisplay from './MasterDisplay/masterDisplay.reducer'
import notifications from './Notifications/notifications.reducer'
import pageBoost from './Page/Boosts/pageBoost.reducer'
import pageCounts from './Page/Counts/pageCounts.reducer'
import pageUsers from './Page/Users/pageUsers.reducer'
import performance from './Performance/performance.reducer'
import apiPlaces from './Places/apiPlaces.reducer'
import userConfig from './UserConfig/userConfig.reducer'
import vedettePlus from './VedettePlus/vedettePlus.reducer'

export type RootState = ReturnType<typeof rootReducer>

const rootReducer = combineReducers({
  apiPlaces,
  notifications,
  display,
  emailing,
  expert360,
  masterDisplay,
  pageCounts,
  pageUsers,
  performance,
  userConfig,
  vedettePlus,
  pageBoost,
})

export default rootReducer
