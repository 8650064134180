import Icon from '@ui-library/core/lib/atoms/Icon'
import { WarningLined } from '@ui-library/icons'
import styled from 'styled-components'
import Banner from '../../../Banner/Banner'

type DownSaleBannerProps = {
  nbMaxAfterDownSale: number
}

const BannerContent = styled.div`
  display: flex;

  p {
    margin: 0.3em;
  }
`

export const DownSaleBanner = ({ nbMaxAfterDownSale }: DownSaleBannerProps) => (
  <Banner animation dataTestId="downSaleBanner">
    <BannerContent>
      <Icon
        icon={WarningLined}
        size="2em"
        color="#ffb75a"
        style={{ marginRight: '1em' }}
      />
      <div>
        <DynamicText nbMaxAfterDownSale={nbMaxAfterDownSale} />
      </div>
    </BannerContent>
  </Banner>
)

const DynamicText = ({ nbMaxAfterDownSale }: DownSaleBannerProps) => {
  const bold =
    nbMaxAfterDownSale > 1
      ? `${nbMaxAfterDownSale} programmes maximum`
      : `${nbMaxAfterDownSale} programme maximum`

  return nbMaxAfterDownSale > 1 ? (
    <p>
      <span>Veillez à ce que </span>
      <b>{bold}</b>
      <span> soient séléctionnés dans la colonne "</span>
      <span>
        <b>
          <u>À conserver</u>
        </b>
      </span>
      <span>".</span>
    </p>
  ) : (
    <p>
      <span>Veillez à ce que </span>
      <b>{bold}</b>
      <span> soit séléctionné dans la colonne "</span>
      <span>
        <b>
          <u>À conserver</u>
        </b>
      </span>
      <span>".</span>
    </p>
  )
}
