import { Icon } from '@ui-library/core'
import { BinRegular } from '@ui-library/icons'
import PenRegular from '@ui-library/icons/PenRegular'
import ShareRegular from '@ui-library/icons/ShareRegular'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { userProfileSelector } from '../../../../redux/reducers/UserConfig/userConfig.reducer'
import { COMPTAGE_PRODUITS } from '../../../../routes.constants'
import { MAFProduct, Role } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { DISPLAY_PRODUCTS } from '../../../../utils/variables'
import ModalRowWrapper from './ModalRowWrapper'

type ActionButtonsMenuProps = {
  idMaf?: string
  mafProduct: MAFProduct | ''
  left: number
  top: number
  closePopin: () => void
  actionType: string
  editUser: () => void
  deleteCount: () => void
  editBoost?: () => void
}

const ActionButtonsMenu = ({
  idMaf,
  mafProduct,
  left,
  top,
  closePopin,
  actionType,
  editUser,
  deleteCount,
  editBoost,
}: ActionButtonsMenuProps): React.ReactElement => {
  const history = useHistory()
  const userProfile = useSelector(userProfileSelector)
  const roleAdmin = userProfile.roles?.includes(Role.ROLE_ADMIN)

  const isExpert360Product = mafProduct === MAFProduct.Expert360
  const isMasterDisplayProduct = mafProduct === MAFProduct.MasterDisplay
  const isEmailingOptinProduct =
    mafProduct !== '' &&
    [MAFProduct.EmailingOptin, MAFProduct.EmailingOptinConstruire].includes(
      mafProduct
    )
  const isEmailingAlertProduct =
    mafProduct !== '' &&
    [MAFProduct.EmailingAlerte, MAFProduct.EmailingAlerteConstruire].includes(
      mafProduct
    )
  const isDisplayProduct =
    mafProduct !== '' && DISPLAY_PRODUCTS.includes(mafProduct)
  const isPerformanceProduct = mafProduct === MAFProduct.DisplayPerformance
  const isVedettePlusProduct = mafProduct === MAFProduct.VedettePlus

  const redirectEditCount = () => {
    if (isExpert360Product)
      return history.push(`/count/expert360/edit/${idMaf}`)
    if (isMasterDisplayProduct)
      return history.push(`/count/masterdisplay/edit/${idMaf}`)
    if (isEmailingOptinProduct)
      return history.push(`/count/emailing/optin/edit/${idMaf}`)
    if (isEmailingAlertProduct)
      return history.push(`/count/emailing/alert/edit/${idMaf}`)
    if (isDisplayProduct) return history.push(`/count/display/edit/${idMaf}`)
    if (isPerformanceProduct)
      return history.push(`/count/performance/edit/${idMaf}`)
    if (isVedettePlusProduct)
      return history.push(`/count/vedetteplus/edit/${idMaf}`)
    return COMPTAGE_PRODUITS
  }

  return (
    <div
      className="backgroundPopin"
      data-testid="backgroundPopin"
      onClick={() => closePopin()}
      aria-hidden="true"
    >
      <ModalRowWrapper left={left} top={top}>
        {actionType === 'users' && (
          <ul>
            <li data-testid="editUser" onClick={editUser} aria-hidden="true">
              <Icon icon={PenRegular} size="16px" className="penRegular" />
              Modifier le profil
            </li>
          </ul>
        )}
        {(actionType === 'myBoost' || actionType === 'allBoost') && (
          <ul>
            <li data-testid="editLi" onClick={editBoost} aria-hidden="true">
              <Icon icon={PenRegular} size="16px" className="penRegular" />
              Modifier
            </li>
          </ul>
        )}
        {(actionType === 'myCounts' || actionType === 'allCounts') && (
          <ul>
            <li
              data-testid="editLi"
              onClick={redirectEditCount}
              aria-hidden="true"
            >
              <Icon icon={PenRegular} size="16px" className="penRegular" />
              Modifier
            </li>
            <li className="unavailable">
              <Icon icon={ShareRegular} size="16px" className="shareRegular" />
              Partager
            </li>
            {actionType === 'myCounts' && (
              <li
                data-testid="deleteCount"
                className={roleAdmin ? '' : 'unavailable'}
                onClick={deleteCount}
                aria-hidden="true"
              >
                <Icon icon={BinRegular} size="16px" className="boxRegular" />
                Supprimer
              </li>
            )}
          </ul>
        )}
      </ModalRowWrapper>
    </div>
  )
}

export default ActionButtonsMenu
