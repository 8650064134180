import React, { useEffect, useState } from 'react'

type DelayedContentProps = {
  delay: number
  children: React.ReactNode
}

const DelayedContent = ({ delay, children }: DelayedContentProps) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delay)

    return () => clearTimeout(timer) // Cleanup the timer on component unmount
  }, [delay])

  return isVisible ? children : null
}

export default DelayedContent
