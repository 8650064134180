import React from 'react'

type OverlayProps = {
  children: React.ReactNode
  closeOverlay?: () => void
}

const Overlay = ({
  closeOverlay,
  children,
}: OverlayProps): React.ReactElement => {
  const handleClick = () => {
    if (closeOverlay) {
      closeOverlay()
    }
  }

  return (
    <div className="wrapperOverlay" data-testid="wrapperOverlay">
      <div
        className="backgroundOverlay"
        data-testid="backgroundOverlay"
        onClick={() => {
          handleClick()
        }}
      />
      <div className="contentOverlay">{children}</div>
    </div>
  )
}

export default Overlay
