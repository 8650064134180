/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { getUserMe } from '../../../services/userApi'
import { getBackendHost } from '../../../utils/getEnv'
import { Market, UserBo } from '../../../utils/myAdfactoryApi/swaggerApi'

interface IUserConfigState {
  loading: string
  error: any
  userProfile: UserBo
  currentMarket: Market
  isUserConnected: boolean
  onProduction: boolean
  redirect: boolean
  tabListCount: string
  tabListBoost: string
  backendHost: string
}

export const initialState: IUserConfigState = {
  loading: 'idle',
  error: null,
  userProfile: {},
  currentMarket: Market.Ancien,
  isUserConnected: false,
  onProduction: false,
  redirect: false,
  tabListCount: 'myCounts', // myCounts or allCounts
  tabListBoost: 'myBoost', // myBoost or allBoost
  backendHost: getBackendHost(),
}

// Slice
const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    setUserConnected(state, action) {
      state.isUserConnected = action.payload
    },
    addUserProfile(state, action) {
      state.userProfile = action.payload
    },
    addJobAndMarketToUserProfile(state, action) {
      state.userProfile.typeProfil = action.payload.typeProfil
      state.userProfile.markets = action.payload.markets
    },
    addCurrentMarket(state, action) {
      state.currentMarket = action.payload
      state.tabListCount = 'myCounts'
    },
    setOnProduction(state, action) {
      state.onProduction = action.payload
    },
    setTabListCount(state, action) {
      state.tabListCount = action.payload
    },
    setTabListBoost(state, action) {
      state.tabListBoost = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.loading = 'idle'
        state.error = null
        state.userProfile = {}
        state.currentMarket = Market.Ancien
        state.isUserConnected = false
        state.onProduction =
          window?.location?.hostname === 'myadfactory.seloger.com'
        state.redirect = false
        state.tabListCount = 'myCounts'
        state.tabListBoost = 'myBoost'
        state.backendHost = getBackendHost()
      })
      .addCase(getUserMe.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(getUserMe.fulfilled, (state, action: any) => {
        // Set Redux with user informations
        state.loading = 'idle'
        state.userProfile = action.payload
        const hostname = window?.location?.hostname
        if (hostname === 'myadfactory.seloger.com') {
          state.onProduction = true // Feature Flipping
        }

        // currentMarket need to be updated with the first value of markets in userProfile
        if (state.userProfile?.markets?.[0]) {
          const [firstMarket] = state.userProfile.markets
          state.currentMarket = firstMarket
        }
        // state.userProfile.roles = ['ROLE_ADMIN'] // uniquement pour tester les pages de management users
        // state.userProfile.typeProfil = null // uniquenment pour tester la première connection

        state.isUserConnected = true
        state.tabListBoost = 'myBoost'
      })
      .addCase(getUserMe.rejected, (state, action: any) => {
        state.loading = 'idle'
        state.error = action.error
        state.redirect = true
      })
  },
})

// Actions
export const {
  setUserConnected,
  addUserProfile,
  addJobAndMarketToUserProfile,
  addCurrentMarket,
  setOnProduction,
  setTabListCount,
  setTabListBoost,
} = userConfigSlice.actions

// Selectors
type UserConfigSliceName = (typeof userConfigSlice)['name']
export type UserConfigStateType = Record<UserConfigSliceName, IUserConfigState>

export const currentMarketSelector = (state: UserConfigStateType) =>
  state.userConfig.currentMarket
export const userProfileSelector = (state: UserConfigStateType) =>
  state.userConfig.userProfile
export const userIdSelector = (state: UserConfigStateType) =>
  state.userConfig.userProfile.id
export const onProductionSelector = (state: UserConfigStateType) =>
  state.userConfig.onProduction
export const backendHostSelector = (state: UserConfigStateType) =>
  state.userConfig.backendHost
export const redirectSelector = (state: UserConfigStateType) =>
  state.userConfig.redirect
export const isUserConnectedSelector = (state: UserConfigStateType) =>
  state.userConfig.isUserConnected
export const tabListCountSelector = (state: UserConfigStateType) =>
  state.userConfig.tabListCount
export const tabListBoostSelector = (state: UserConfigStateType) =>
  state.userConfig.tabListBoost

export default userConfigSlice.reducer
