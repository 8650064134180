import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Icon } from '@ui-library/core'
import { CloseRegular } from '@ui-library/icons'

import AutocompleteUnique from '../../Autocomplete/AutocompleteUnique/AutocompleteUnique'
import Overlay from '../Overlay'

import {
  expert360DataSelector,
  setData,
} from '../../../redux/reducers/Expert360/expert360.reducer'

import { fetchCatchmentZoneClient } from '../../../services/expert360Api'
import { productNamesType } from '../../../types/productNames.types'
import { ClientBo } from '../../../utils/myAdfactoryApi/swaggerApi'

type OverlayRcClientProps = {
  url: string
  closeOverlay: () => void
}

const OverlayRcClient = ({ url, closeOverlay }: OverlayRcClientProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const data = useSelector(expert360DataSelector)

  const [errorRcClient, setErrorRcClient] = useState<boolean>(false)
  const [valueRcClient, setValueRcClient] = useState<ClientBo[]>([])

  const handleRcClient = useCallback((value: ClientBo[]) => {
    setValueRcClient(value)
    if (value.length !== 0) {
      setErrorRcClient(false)
    }
  }, [])

  const setRcClient = () => {
    const updatedData = {
      ...data,
      clientId: valueRcClient?.[0]?.clientId,
      clientName: valueRcClient?.[0]?.clientName,
    }
    dispatch(setData(updatedData))
    dispatch(fetchCatchmentZoneClient({ clientId: valueRcClient[0].clientId }))
    history.push(url)
  }

  const disableOverlay = () => {
    closeOverlay()
  }

  //-----------------------------------------------------------------------
  // Return part

  return (
    <Overlay closeOverlay={() => disableOverlay()}>
      <div className="overlayRcCLient" data-testid="overlayRcCLient">
        <div
          data-testid="closeOverlay"
          className="closeOverlay"
          onClick={() => {
            disableOverlay()
          }}
        >
          <Icon icon={CloseRegular} />
        </div>
        <div className="wrapperOverlayRcClient">
          <h2 data-testid="overlayRcClientTitle">
            {productNamesType.Expert360}
          </h2>
          <p>
            Saisissez le numéro RC ou la raison sociale de votre client pour
            obtenir une proposition sur mesure.
          </p>
          <AutocompleteUnique
            label="RC client ou raison sociale"
            placeholder="Ex: 123456 ou Century 21"
            autocompleteCallback={(selected) =>
              handleRcClient(selected as ClientBo[])
            }
            error={errorRcClient}
            noResultFound="Ce RC client est introuvable. Merci de vous rapprocher de votre marché pour vérifier l'éligibilité de votre client."
          />
          <div
            className="wrapperbuttonValidate"
            data-testid="overlayRcClientButton"
          >
            <Button
              tag="button"
              size="medium"
              kind="primary"
              variant="normal"
              onClick={setRcClient}
              disabled={valueRcClient.length === 0}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayRcClient
