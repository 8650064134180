export const COMPTAGE = '/comptage'
export const BOOST = '/boost'
export const PROFILE = '/profile'
export const USERS = '/users'

export const EXPERT_360_NEW = '/count/expert360/new'
export const EXPERT_360_EDIT = '/count/expert360/edit/:id'
export const EXPERT_360_PREVIEW = '/count/expert360/preview/:id'

export const MASTER_DISPLAY_NEW = '/count/masterdisplay/new'
export const MASTER_DISPLAY_PREVIEW = '/count/masterdisplay/preview/:id'

export const EMAILING_OPTIN_NEW = '/count/emailing/optin/new'
export const EMAILING_OPTIN_EDIT = '/count/emailing/optin/edit/:id'
export const EMAILING_OPTIN_PREVIEW = '/count/emailing/optin/preview/:id'

export const EMAILING_ALERTE_NEW = '/count/emailing/alert/new'
export const EMAILING_ALERTE_EDIT = '/count/emailing/alert/edit/:id'
export const EMAILING_ALERTE_PREVIEW = '/count/emailing/alert/preview/:id'

export const DISPLAY_IMPRESSION_NEW = '/count/display/new'
export const DISPLAY_IMPRESSION_EDIT = '/count/display/edit/:id'
export const DISPLAY_IMPRESSION_PREVIEW = '/count/display/preview/:id'

export const PERFORMANCE_NEW = '/count/performance/new'
export const PERFORMANCE_EDIT = '/count/performance/edit/:id'
export const PERFORMANCE_PREVIEW = '/count/performance/preview/:id'

export const VEDETTEPLUS_NEW = '/count/vedetteplus/new'
export const VEDETTEPLUS_EDIT = '/count/vedetteplus/edit/:id'
export const VEDETTEPLUS_PREVIEW = '/count/vedetteplus/preview/:id'

export const COMPTAGE_PRODUITS = '/comptage/produits'

export const BOOST_PRODUITS = '/boost/produits'
export const BOOST_SOCIAL_IMMO_NEW = '/boost/boostsocialimmo/new'
