import coverCardDisplayImpression from '../assets/images/cardCover/cover-card-display-impression.svg'
import coverCardEmailAlerte from '../assets/images/cardCover/cover-card-email-alerte.svg'
import coverCardEmailOption from '../assets/images/cardCover/cover-card-email-optin.svg'
import coverCardExpert360 from '../assets/images/cardCover/cover-card-expert-360.svg'
import coverCardMasterDisplay from '../assets/images/cardCover/cover-card-master-display.svg'
import billboardLuxurySvg from '../assets/images/display/billboardLuxury.svg'
import mastheadSvg from '../assets/images/display/masthead.svg'
import mastheadLuxurySvg from '../assets/images/display/mastheadLuxury.svg'
import natifSvg from '../assets/images/display/natif.svg'
import natifLuxurySvg from '../assets/images/display/natifLuxury.svg'
import paveSvg from '../assets/images/display/pave.svg'
import bellesDemeures from '../assets/images/medium/bellesDemeures.svg'
import logicImmo from '../assets/images/medium/logicImmo.svg'
import logicImmoNeuf from '../assets/images/medium/logicImmoNeuf.svg'
import luxResidence from '../assets/images/medium/luxResidence.svg'
import seLoger from '../assets/images/medium/seLoger.svg'
import seLogerBuCom from '../assets/images/medium/seLogerBuCom.svg'
import seLogerConstruire from '../assets/images/medium/seLogerConstruire.svg'
import seLogerNeuf from '../assets/images/medium/seLogerNeuf.svg'

import pdfDisplayImpression from '../assets/pdf/pdf-display-impression.pdf'
import pdfDisplayLuxury from '../assets/pdf/pdf-display-luxury.pdf'
import pdfEmailing from '../assets/pdf/pdf-emailing.pdf'
import pdfMasterDisplay from '../assets/pdf/pdf-master-display.pdf'

import { BroadcastMedium as BroadcastMediumType } from '../types/BroadCastMedium.type'
import { ListProducts } from '../types/ProductsCard'
import { LabelValueType } from '../types/ResumeCard.type'
import { productNamesType } from '../types/productNames.types'
import { MAFProduct } from './myAdfactoryApi/swaggerApi'

// Ancien > Expert 360
export const EXPERT_360_PRICE_PRINT_OPTION: number = 68
export const EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER: number = 150
export const EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION: number = 318
export const EXPERT_360_BOOST_PERCENT_FOR_EXTERNAL_URL_OPTION: number = 0.3

// Ancien > Master Display
export const MASTER_DISPLAY_CPM: number = 125

// Neuf > Display
export const COMMON_DISPLAY_PRODUCTS_FORMATS: { [key: string]: any } = {
  Masthead: {
    price: 30,
    svg: mastheadSvg,
  },
  Natif: {
    price: 4,
    svg: natifSvg,
  },
  Pavé: {
    price: 4,
    svg: paveSvg,
  },
}

// Neuf > Performance
export const PERFORMANCE_PRODUCTS_FORMATS: { [key: string]: any } = {
  Masthead: {
    svg: mastheadSvg,
  },
  Pave: {
    svg: paveSvg,
  },
}

export const DISPLAY_PRODUCTS_FORMATS: { [key: string]: any } = {
  Neuf: {
    ...COMMON_DISPLAY_PRODUCTS_FORMATS,
    // Extra format for Neuf in addition to the common formats
    'Grand Angle': {
      price: 4,
      svg: paveSvg,
    },
  },
  Luxe: {
    Masthead: {
      price: 4,
      title: 'Prestige Display',
      svg: mastheadLuxurySvg,
      productName: 'Masthead',
      description: 'Grande bannière promotionnelle, placée en haut de page',
      popinContent: {
        logos: [mastheadLuxurySvg, mastheadLuxurySvg],
        title: 'Format Masthead (retargeting inclus)',
        description:
          'Grande bannière promotionnelle, placée en haut de page faisant de votre marque un partenaire privilégiée à nos plateformes luxes.',
        notice: '',
      },
    },
    Billboard: {
      price: 4,
      title: 'Power Display',
      svg: billboardLuxurySvg,
      productName: 'Billboard',
      description:
        'Large bannière promotionnelle, affichée dans le premier encart',
      popinContent: {
        logos: [billboardLuxurySvg, natifLuxurySvg],
        title: 'Format Billboard (retargeting inclus)',
        description:
          'Large bannière promotionnelle affichée sous la première annonce et remplacée par un Natif inter-annonces sur Lux Résidence.',
        notice: 'Produit non compatible avec le format Natif inter-annonces.',
      },
    },
    Natif: {
      price: 4,
      title: 'Access Display',
      svg: natifLuxurySvg,
      productName: 'Natif inter-annonces',
      description: 'Bannière promotionnelle',
      popinContent: {
        logos: [natifLuxurySvg, natifLuxurySvg],
        title: 'Natif inter-annonces',
        description:
          'Banière promotionnelle affichée sous la 5ème annonce renforçant la visibilité de votre marque sur nos plateformes luxes.',
        notice: 'Produit non compatible avec le format Billboard.',
      },
    },
  },
  Construire: {
    Masthead: {
      price: 30,
      svg: mastheadSvg,
    },
    Natif: {
      price: 4,
      svg: natifSvg,
    },
    Pave: {
      price: 4,
      svg: paveSvg,
    },
  },
  BureauxEtCommerce: {
    Masthead: {
      svg: mastheadSvg,
    },
    Natif: {
      svg: natifSvg,
    },
    'Grand Angle': {
      svg: natifSvg,
    },
    'Masthead+': {
      svg: natifSvg,
    },
  },
}

export const productsBoost: ListProducts = {
  Neuf: [
    {
      tag: ['Performance'],
      cardCover: coverCardExpert360,
      title: 'Boost Social Immo',
      description:
        'Vos biens à vendre sur les réseaux sociaux. Boostez auprès de nos visiteurs une sélection de programmes par mois.',
      available: true,
      productName: 'Boost Social Immo',
      url: '/boost/boostsocialimmo/new',
    },
  ],
}

export const productsCounts: ListProducts = {
  Ancien: [
    {
      tag: ['Notoriété', 'Package'],
      title: productNamesType.Expert360,
      description:
        'Vos points de contact démultipliés grâce à un dispositif 360° alliant la puissance de nos médias digitaux.',
      cardCover: coverCardExpert360,
      pdfLink: 'https://pro.groupeseloger.com/solution/ancien-expert-360/',
      available: true,
      productName: 'expert360',
      url: '/count/expert360/new',
    },
    {
      tag: ['Notoriété'],
      title: productNamesType.MasterDisplay,
      description:
        'Impact maximum avec ce format situé en partie supérieure des sites Seloger et Logic immo, idéal pour la visibilité. Duo ou solo, Récurrent ou ponctuel.',
      cardCover: coverCardMasterDisplay,
      pdfLink: pdfMasterDisplay,
      available: true,
      productName: 'masterdisplay',
      url: '/count/masterdisplay/new',
    },
  ],
  Neuf: [
    {
      tag: ['Notoriété', 'Performance'],
      title: productNamesType.EmailingOptin,
      base: 'Optin',
      description:
        'Utilisateurs ayant coché la case "Recevoir les offres des partenaires" dans leur espace personnel. Ils recevront un email au design de votre marque',
      cardCover: coverCardEmailOption,
      pdfLink: pdfEmailing,
      available: true,
      productName: 'emailing',
      url: '/count/emailing/optin/new',
    },
    {
      tag: ['Notoriété', 'Performance'],
      title: productNamesType.EmailingAlerte,
      base: 'Alerte',
      description:
        'Cible les internautes ayant coché la case "partager mes informations" au moment de créer leur alerte. Ils recevront un mail à la charte des alertes du groupe SeLoger.',
      cardCover: coverCardEmailAlerte,
      pdfLink: pdfEmailing,
      available: true,
      productName: 'emailing',
      url: '/count/emailing/alert/new',
    },
    {
      tag: ['Masthead', 'Natif', 'Pavé', 'Grand Angle'],
      title: productNamesType.MasterDisplayNeuf,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      pdfLink: pdfDisplayImpression,
      available: true,
      productName: 'display',
      url: '/count/display/new',
    },
    {
      tag: ['Notoriété'],
      title: productNamesType.VedettePlus,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      available: true,
      productName: 'vedetteplus',
      url: '/count/vedetteplus/new',
    },
    {
      tag: ['Masthead', 'Pavé'],
      title: productNamesType.DisplayPerformance,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      available: true,
      productName: 'performance',
      url: '/count/performance/new',
    },
  ],
  Luxe: [
    {
      tag: ['Notoriété', 'Package'],
      title: productNamesType.DisplayLuxury,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      pdfLink: pdfDisplayLuxury,
      available: true,
      productName: 'display',
      url: '/count/display/new',
    },
  ],
  Construire: [
    {
      tag: ['Notoriété', 'Performance'],
      title: productNamesType.EmailingOptinConstruire,
      base: 'Optin',
      description:
        'Utilisateurs ayant coché la case "Recevoir les offres des partenaires" dans leur espace personnel. Ils recevront un email au design de votre marque',
      cardCover: coverCardEmailOption,
      available: true,
      productName: 'emailing',
      url: '/count/emailing/optin/new',
    },
    {
      tag: ['Notoriété', 'Performance'],
      title: productNamesType.EmailingAlerteConstruire,
      base: 'Alerte',
      description:
        'Cible les internautes ayant coché la case "partager mes informations" au moment de créer leur alerte. Ils recevront un mail à la charte des alertes du groupe SeLoger.',
      cardCover: coverCardEmailAlerte,
      available: true,
      productName: 'emailing',
      url: '/count/emailing/alert/new',
    },
    {
      tag: ['Masthead', 'Natif', 'Pavé'],
      title: productNamesType.DisplayConstruire,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      available: true,
      productName: 'display',
      url: '/count/display/new',
    },
  ],
  BureauxEtCommerce: [
    {
      tag: ['Masthead', 'Natif', 'Pavé'],
      title: productNamesType.DisplayBureauxEtCommerce,
      description:
        'Le display est un support publicitaire pour communiquer sur votre marque, votre offre et vos temps forts. Reboostez vos efforts commerciaux par une présence média plus globale.',
      cardCover: coverCardDisplayImpression,
      available: true,
      productName: 'display',
      url: '/count/display/new',
    },
  ],
}

export const MASTERDISPLAY_PROJECT_TYPE: LabelValueType[] = [
  {
    label: 'Achat',
    value: 'achat',
  },
  {
    label: 'Location',
    value: 'location',
  },
]

export const MASTERDISPLAY_BROADCAST_MEDIUM: LabelValueType[] = [
  {
    label: 'Seloger',
    value: 'seloger',
  },
  {
    label: 'Logic Immo',
    value: 'logicimmo',
  },
]

export const MASTERDISPLAY_CONTRACT_TYPE: LabelValueType[] = [
  {
    label: 'Récurrent',
    value: 'recurrent',
  },
]

export const jobList: LabelValueType[] = [
  {
    label: 'Commerce',
    value: 'commerce',
    testId: 'commerce',
  },
  {
    label: 'Studio',
    value: 'studio',
    testId: 'studio',
  },
  {
    label: 'Opérations Adfactory',
    value: 'adfactoryOperations',
    testId: 'adfactoryOperations',
  },
  {
    label: 'Autres (Marketing, Data, Finance, …)',
    value: 'others',
    testId: 'others',
  },
]

export const marketList: LabelValueType[] = [
  {
    label: 'Ancien',
    value: 'Ancien',
  },
  {
    label: 'Neuf',
    value: 'Neuf',
  },
  {
    label: 'Construire',
    value: 'Construire',
  },
  {
    label: 'Luxe',
    value: 'Luxe',
  },
  {
    label: 'Bureaux et commerces',
    value: 'BureauxEtCommerce',
  },
]

export const ITEMS_PER_PAGE: number = 20
export const ADMIN: string = 'ROLE_ADMIN'

export const choicesBoost: any = [
  {
    label: 'Mes Boost',
    id: 'tab-1',
    ariaControls: 'panel-1',
  },
  {
    label: 'Tous les Boost',
    id: 'tab-2',
    ariaControls: 'panel-2',
  },
]

export const choicesCounts: any = [
  {
    label: 'Mes comptages',
    id: 'tab-1',
    ariaControls: 'panel-1',
  },
  {
    label: 'Tous les comptages',
    id: 'tab-2',
    ariaControls: 'panel-2',
  },
]

export const choicesUsers: any = [
  {
    label: 'Tous les utilisateurs',
    id: 'tab-1',
    ariaControls: 'panel-1',
  },
]

export const PRODUCTS_BROADCAST_MEDIUM: BroadcastMediumType[] = [
  {
    label: 'Seloger',
    value: 'SELOGER',
    selected: true,
    logo: seLoger,
  },
  {
    label: 'Logic Immo',
    value: 'LOGICIMMO',
    selected: true,
    logo: logicImmo,
  },
  {
    label: 'Seloger Neuf',
    value: 'SELOGERNEUF',
    selected: true,
    logo: seLogerNeuf,
  },
  {
    label: 'LogicImmo Neuf',
    value: 'LOGICIMMONEUF',
    selected: true,
    logo: logicImmoNeuf,
  },
  {
    label: 'Belles Demeures',
    value: 'BELLESDEMEURES',
    selected: true,
    logo: bellesDemeures,
  },
  {
    label: 'Lux Residence',
    value: 'LUXRESIDENCE',
    selected: true,
    logo: luxResidence,
  },
  {
    label: 'Seloger Construire',
    value: 'SELOGERCONSTRUIRE',
    selected: true,
    logo: seLogerConstruire,
  },
  {
    label: 'Seloger Bureaux et Commerce',
    value: 'SELOGERBUREAUXETCOMMERCE',
    selected: true,
    logo: seLogerBuCom,
  },
]

export const EMAILING_PRODUCTS = [
  MAFProduct.EmailingAlerte,
  MAFProduct.EmailingOptin,
  MAFProduct.EmailingAlerteConstruire,
  MAFProduct.EmailingOptinConstruire,
]

export const DISPLAY_PRODUCTS = [
  MAFProduct.MasterDisplayNeuf,
  MAFProduct.DisplayLuxury,
  MAFProduct.DisplayConstruire,
  MAFProduct.DisplayBureauxCommerce,
]

export const PERIODICITY_OPTIONS = [
  {
    label: 'Récurrent',
    value: 'Récurrent',
  },
  {
    label: 'One Shot',
    value: 'OneShot',
  },
]

export const BROADCASTMEDIUMS_FOR_BUCOM = [
  'SELOGER',
  'SELOGERBUREAUXETCOMMERCE',
]
