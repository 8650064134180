import React from 'react'
import styled from 'styled-components'
import { respondTo } from '../../../theme/mixins/respondTo'
import CardContacts from '../../Cards/CardContacts/CardContacts'

const contactsContent = [
  {
    id: 0,
    title: 'Problème technique',
    text: (
      <>
        <p>Une amélioration à apporter à MyAdfactory ?</p>
        <p>Un dysfonctionnement ?</p>
      </>
    ),
    contact: 'advertising@aviv-group.com',
  },
  {
    id: 1,
    title: 'Suivi de campagne',
    text: (
      <>
        <p>Besoin d'en savoir plus sur une campagne en ligne ?</p>
        <p>Le suivi ?</p>
      </>
    ),
    contact: 'adfactory@aviv-group.com',
  },
  {
    id: 2,
    title: 'Création de bannière',
    text: (
      <>
        <p>Une créa. à modifier ?</p>
        <p>Besoin de conseils crea. pour une bannière ?</p>
      </>
    ),
    contact: 'production.flashagence@aviv-group.com',
  },
]

const HomepageContactsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2em;
  width: 100%;

  ${respondTo.lg`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const HomepageContacts = (): React.ReactElement => (
  <HomepageContactsWrapper data-testid="homepageContacts">
    {contactsContent.map((contact) => (
      <CardContacts
        key={contact.id}
        title={contact.title}
        text={contact.text}
        contact={contact.contact}
      />
    ))}
  </HomepageContactsWrapper>
)

export default HomepageContacts
